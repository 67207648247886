import { Suspense, useEffect } from "react";
import { AppRouting } from "./app.routing";
import ComponentLoader from "./components/component-loader/component-loader";
import { useTranslation } from "react-i18next";
import { LanguagesEnum } from "./core/enums/languages.enum";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
 
       i18n.changeLanguage(LanguagesEnum.EN);
      localStorage.setItem("language", LanguagesEnum.EN);
     
  }, [i18n]);

  return (
    <Suspense fallback={<ComponentLoader />}>
      <main
        className="font"
        dir={i18n.language === LanguagesEnum.AR ? "rtl" : "ltr"}
      >
        <AppRouting />
      </main>
    </Suspense>
  );
}

export default App;
